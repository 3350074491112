import { getS3BucketUrl } from 'utils/url';

interface Props {
  asPath: string
}

const HeadApp: React.FC<Props> = ({ asPath }) => {
  const s3 = getS3BucketUrl();

  const canonicalUrl = typeof window === 'undefined'
    ? `https://app.happyneuron.academy${asPath}`
    : `${window.location.origin}${window.location.pathname}`;

  return (
    <>
      <link rel="icon" type="image/png" href="/logo-HNA-small.svg" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <link rel="canonical" href={canonicalUrl} />
      <meta name="google-site-verification" content="WsvpdLKTzu-oey5m91mHgjQJOFtFPKAylzrAuXHTDB8" />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link rel="preconnect" href={s3} />
      <link rel="dns-prefetch" href={s3} />
    </>
  );
};

export default HeadApp;
