import styled, { css } from 'styled-components';

const DivFooter = styled.footer<any>`
	background-color: var(--color-black-02);
	bottom: 0;
	display: flex;
	flex-wrap: wrap;
	flex-direction: ${({ mobile }) => mobile && 'column'};
	height:  ${({ mobile }) => (mobile ? '100%' : 'var(--footer-height)')};
	justify-content: space-between;
	padding: 1vh 2vw;
	position: ${({ mobile }) => !mobile && 'absolute'};
	width: 100%;
  font-size: var(--size-14);
	white-space: nowrap;

	button {
		border: none;
		background: none;
		padding: 0;
		outline: none;
		cursor: pointer;
	}

	a,
	span, button {
		color: var(--color-gray-light);
	}
	a:hover, button:hover {
		color: inherit;
		text-decoration: underline;
	}

	.box {
		align-items: center;
		display: flex;
	}

`;

const DivBox = styled.div<any>`
	align-items: center;
	display: flex;
	flex-wrap: ${({ mobile }) => mobile && 'wrap'};


`;

const DivSeparator = styled.div`
	background-color: var(--color-gray-light);
	height: 60%;
	margin: 0 15px;
	width: 2px;
`;

const imgWrap = css`
	min-width: 1vw;
	height: 100%;
	display: flex;
	margin-right: 0.5208333333333333vw;
`;

export default {
  DivFooter,
  DivBox,
  DivSeparator,
  imgWrap,
};
