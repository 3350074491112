import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle<any>`
  .froala-editor-custom * {
    max-width: ${({ mobile: m }) => (!m && 'calc(var(--content-width))')};
    font-family: Merriweather;
  }

  .fr-view > p {
    font-family: Merriweather;
    font-weight: inherit;
    font-size: var(--size-20);
    line-height: 1.9rem;
    color: #262626;
    text-align: left;
    border-left: none;
    padding-left: 0;
    background-color: transparent;
  }
`;

export default GlobalStyle;
