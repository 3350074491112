/* eslint-disable import/prefer-default-export */

export const getS3BucketUrl = () => `https://${process.env.S3_BUCKET}.s3.${process.env.REGION}.amazonaws.com`;

export const getPermanentUrl = (fileName: string, suffix?: string) => {
  if (!fileName) return null;
  if (fileName.startsWith('https://')) {
    return fileName;
  }
  const newFilename = suffix ? fileName?.replace(/(.[\w\d_-]+)$/i, `${suffix}$1`) : fileName;
  return `${getS3BucketUrl()}/${newFilename}`;
};

/**
 * If url is absolute URL:
 *   1. Return complete URL if domain is equal as current env
 *   2. Return only the path (after domain) if different
 */
export const getRelativeLinkFromAbsoluteURL = (url: string) => {
  const currentDomain = extractHostname(location.origin);
  const urlDomain = extractHostname(url);

  if (currentDomain === urlDomain) return url; // 1

  return url.split(`${urlDomain}/`)[1]; // 2
};

function extractHostname(url: string) {
  let hostname;

  // find & remove protocol (http, ftp, etc.) and get hostname
  if (url.indexOf('//') > -1) {
    hostname = url.split('/')[2];
  } else {
    hostname = url.split('/')[0];
  }

  // find & remove port number
  hostname = hostname.split(':')[0];
  // find & remove "?"
  hostname = hostname.split('?')[0];

  return hostname;
}
