/* eslint-disable react/no-danger */
/* eslint-disable @next/next/next-script-for-ga */

import { FC } from 'react';

export const GtmComponent: FC = () => (
  <script dangerouslySetInnerHTML={{
    __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-${process.env.GTM_KEY}');`,
  }}
  />
);

export const GtmNoScript: FC = () => (
  <noscript>
    <iframe
      title="GTM"
      src={`https://www.googletagmanager.com/ns.html?id=GTM-${process.env.GTM_KEY}`}
      height="0"
      width="0"
      style={{ display: 'none', visibility: 'hidden' }}
    />
  </noscript>
);

export const RumComponent: FC = () => (
  <script
    dangerouslySetInnerHTML={{
      __html: `(function(n,i,v,r,s,c,x,z){x=window.AwsRumClient={q:[],n:n,i:i,v:v,r:r,c:c};window[n]=function(c,p){x.q.push({c:c,p:p});};z=document.createElement('script');z.async=true;z.src=s;document.head.insertBefore(z,document.getElementsByTagName('script')[0]);})('cwr','${process.env.RUM_CWR}','1.0.0','eu-west-1','https://client.rum.us-east-1.amazonaws.com/1.0.2/cwr.js',{sessionSampleRate:1,guestRoleArn:'${process.env.RUM_GUEST_ROLE_ARN}',identityPoolId:'${process.env.RUM_IDENTITY_POOL_ID}',endpoint:"https://dataplane.rum.eu-west-1.amazonaws.com",telemetries:["performance","errors","http"],allowCookies:true,enableXRay:true});`,
    }}
  />
);
