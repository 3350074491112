const isMobileView = ({ ctx }) => {
  let mobile: any = ctx?.req?.headers['cloudfront-is-mobile-viewer'] === 'true';
  if (!ctx?.req) {
    mobile = navigator?.userAgent?.match(
      /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i,
    );
  }
  return Boolean(mobile);
};

export default isMobileView;
