import { withSSRContext } from 'aws-amplify';

const hnaRequest = async (method: string, path: string, body?: any, APISsr?: any) => {
  try {
    const { API: APICtx } = withSSRContext();
    const API = (APISsr && APISsr !== 'undefined') ? APISsr : APICtx;

    const { data } = await API[method]('hna', path, {
      body: body || null,
      response: true,
      headers: null,
    });
    return data;
  } catch (error) {
    console.error('hnarequest = ', { error });
    throw error;
  }
};

export default hnaRequest;
