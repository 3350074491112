import { Amplify, Analytics, AWSKinesisFirehoseProvider } from 'aws-amplify';
import awsConfig from 'config/amplify';
import kinesisConfig from 'config/kinesis';

Amplify.configure({
  ...awsConfig,
  ssr: true,
  ...kinesisConfig,
});

Analytics.addPluggable(new AWSKinesisFirehoseProvider());

const analyticsRecord = async (data) => {
  try {
    Amplify.configure({
      ...awsConfig,
      ssr: true,
      ...kinesisConfig,
    });
    Analytics.record({
      data,
      immediate: true,
      streamName: process.env.KINESIS_STREAM,
    } as any, 'AWSKinesisFirehose');
  } catch (error) {
    console.log({ error });
  }
};

export default analyticsRecord;
