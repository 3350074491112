// context part
import { useRouter } from 'next/router';
import {
  createContext, ReactNode, useEffect, useState,
} from 'react';
import connectRoutes from 'utils/connectRoutes';
import getIdsFromUrl from 'utils/getIdsFromUrl';

interface HistoryProps {
  children: ReactNode
  querySSR: any
  pathname: string
}

export type HistoryContextType = {
  prevUrl: any
  savedUrl: any
  query: any
  pathname: string
  home: string
}

const initialContext = {
  prevUrl: null,
  savedUrl: null,
  query: { home: ['mon-academy'] },
  pathname: 'mon-academy',
  home: 'mon-academy',
};

const parseUrl = ({ asPath, pathname, query }) => ({
  asPath,
  pathname,
  query,
}
);

export const HistoryContext = createContext<HistoryContextType>(initialContext);

const HistoryProvider = ({ children, querySSR, pathname }: HistoryProps) => {
  const router = useRouter();
  const [prevUrl, setPrevUrl] = useState(parseUrl(router));
  const [savedUrl, setSavedUrl] = useState(null);

  const ignorePaths = ['/formateurs', '/cgu', '/confidentialite', '/qualite', '/commandes', '/mentions-legales'];

  useEffect(() => () => {
    const id = getIdsFromUrl(router?.query?.home?.[1]);
    if (connectRoutes.includes(window.location.pathname)
    && !connectRoutes.map((el) => router.asPath.includes(el)).includes(true)) {
      setSavedUrl(router.asPath);
    }
    if (window.location.pathname.includes('products') || router.asPath.includes('products')) {
      setPrevUrl((e) => {
        if (e.asPath === window.location.pathname) {
          return e;
        }
        return parseUrl(router);
      });
    }
    if (((!ignorePaths.includes(router.asPath) && !id)
    || (window.location.pathname === '/formateurs' && id)) && (!connectRoutes.includes(router.asPath))) {
      setPrevUrl(parseUrl(router));
    }
  },
  [router.asPath]);

  const query = router?.query || querySSR;
  return (
    <HistoryContext.Provider value={{
      prevUrl,
      savedUrl,
      query,
      pathname: router?.asPath || pathname,
      home: query?.home?.[0] || initialContext.home,
    }}
    >
      {children}
    </HistoryContext.Provider>
  );
};

export default HistoryProvider;
