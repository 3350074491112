import dynamic from 'next/dynamic';

const HeaderPayment = dynamic(() => import('containers/HeaderPayment'), {
  ssr: false,
});
const InfosPerso = dynamic(() => import('containers/InfosPerso'), {
  ssr: false,
});
const QualityIndicator = dynamic(() => import('components/QualityIndicator'), {
  ssr: false,
});
const LegalMention = dynamic(() => import('components/LegalMention'), {
  ssr: false,
});
const Confidentiality = dynamic(() => import('components/Confidentiality'), {
  ssr: false,
});
const Commandes = dynamic(() => import('components/Commandes'), {
  ssr: false,
});
const Payment = dynamic(() => import('containers/Payment'), {
  ssr: false,
});
const Coaches = dynamic(() => import('containers/Coaches'), {
  ssr: false,
});
const Cgu = dynamic(() => import('components/Cgu'), {
  ssr: false,
});
const Search = dynamic(() => import('containers/Search'), {
  ssr: false,
});
const Cookies = dynamic(() => import('containers/Cookies'), {
  ssr: false,
});

const Exchanges = dynamic(() => import('containers/Exchanges'), {
  ssr: true,
});

export default {
  HeaderPayment,
  InfosPerso,
  Exchanges,
  QualityIndicator,
  LegalMention,
  Confidentiality,
  Commandes,
  Payment,
  Coaches,
  Cgu,
  Search,
  Cookies,
};
