/* eslint-disable import/prefer-default-export */

export const oneDayinMs = 86400000;

export const isUserTrial = (user) => {
  const today = new Date().valueOf();
  const trialEndDate = user?.trial?.trialEndDate;

  const daysLeft = trialEndDate
    ? (new Date(trialEndDate).valueOf() - today) / oneDayinMs
    : 0;

  if (user?.trial?.trialStatus && daysLeft > 0) return { trial: true, daysLeft };
  return { trial: false, daysLeft };
};

export const isUserSubscribed = (user) => (user?.subscription?.active && user?.subscription?.type === 'subscriber');
