/// <reference types="styled-components/cssprop" />
// import 'antd/dist/reset.css';
import 'styles/globalStyles.css';
import 'froala-editor/css/froala_style.min.css';
import '@draft-js-plugins/emoji/lib/plugin.css';

import type { AppProps, AppContext } from 'next/app';
import { useEffect, useState } from 'react';
import App from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { withSSRContext, Amplify } from 'aws-amplify';
import { ThemeProvider } from 'styled-components';

import AuthProvider from 'providers/Auth';
import HistoryProvider from 'providers/History';
import TrackingProvider from 'providers/Tracking';

import useCookies from 'hooks/useCookies';

import { GtmComponent, GtmNoScript, RumComponent } from 'components/Script';
import HeadApp from 'components/HeadApp';
import ModalPage from 'components/ModalPage';
import Footer from 'components/Footer';

import Cookies from 'containers/Cookies';

import { awsConfig, gqlConfig, kinesisConfig } from 'config';
import {
  connectRoutes, handleAllSettledRes, hnaRequest, isMobileView,
} from 'utils';

import GlobalStyle from 'styles/global';

Amplify.configure({
  ssr: true,
  ...awsConfig,
  ...kinesisConfig,
  ...gqlConfig,
});

if (process.env.NODE_ENV === 'production') console.log = () => {};

const MyApp = ({ Component, pageProps }: AppProps) => {
  const [openModal, setOpenModal] = useState('');
  const [mobile, setMobile] = useState(pageProps?.mobile);
  const router = useRouter();
  const { displayCookie, handleCookie, hasAccepted } = useCookies();

  const handleClose = () => setOpenModal('');
  const handleClickLogo = () => {
    router.push('/mon-academy', undefined, { shallow: true });
  };

  useEffect(() => {
    const m = isMobileView({ ctx: {} });
    if (m) setMobile(m);
  }, []);

  const gtmIsActive = (hasAccepted || pageProps.cookies?.cookies || pageProps.user)
   && process.env.GTM_KEY;
  const rumIsActive = (hasAccepted || pageProps.cookies?.cookies || pageProps.user)
  && process.env.RUM_CWR && process.env.ENV !== 'local';
  // console.log('BONJOUR APP');
  return (
    <>
      <Head>
        {gtmIsActive && <GtmComponent />}
        {rumIsActive && <RumComponent />}
        <HeadApp asPath={pageProps?.asPath} />
      </Head>
      {gtmIsActive && (
        <GtmNoScript />
      )}
      <HistoryProvider querySSR={pageProps.querySSR} pathname={pageProps.pathname}>
        <AuthProvider user={pageProps.user}>
          <TrackingProvider>
            <ThemeProvider theme={{ mobile }}>
              {(!pageProps.user && displayCookie && pageProps.cookies?.cookies === undefined)
              && (
                <Cookies handleCookie={handleCookie} setOpenModal={setOpenModal} />
              )}
              <GlobalStyle mobile={mobile} />

              <Component {...pageProps} mobile={mobile} setOpenModal={setOpenModal} />

              <ModalPage
                openModal={openModal}
                handleClickLogo={handleClickLogo}
                handleClose={handleClose}
              />

            </ThemeProvider>
            {!mobile && (
            <Footer
              isConnected={pageProps.user || pageProps.user?.profile?.isHNPROMember}
              setOpenModal={setOpenModal}
            />
            )}
          </TrackingProvider>
        </AuthProvider>
      </HistoryProvider>

    </>
  );
};
MyApp.getInitialProps = async (appContext: AppContext) => {
  if (process.env.NODE_ENV === 'production') console.log = () => {};

  const appProps = await App.getInitialProps(appContext);

  const { Auth, API } = withSSRContext({ req: appContext?.ctx?.req });
  const mobile = isMobileView(appContext);
  const hasLoginToken = appContext?.ctx?.query?.token || appContext?.ctx?.query?.confirmToken;

  try {
    const user = await Auth.currentAuthenticatedUser();

    if (user && !hasLoginToken
    && connectRoutes.find((route) => appContext.ctx?.asPath.includes(route))) {
      appContext?.ctx?.res?.writeHead(302, { Location: '/mon-academy' });
      appContext?.ctx?.res?.end();
    }
    const promises: any = await Promise.allSettled([
      hnaRequest('get', 'user/preference/list', undefined, API),
      hnaRequest('get', process.env.USER_URL, undefined, API),
    ]);

    const preferencesList = handleAllSettledRes(promises?.[0]);
    if (preferencesList?.themes.length) {
      preferencesList.themes.sort((a, b) => (a.name < b.name ? -1 : 1));
    }
    return {
      ...appProps,
      pageProps: {
        preferencesList,
        user: handleAllSettledRes(promises?.[1])?.user,
        mobile,
        pathname: appContext.ctx?.asPath,
      },
    };
  } catch (error) {
    console.error('App = ', error);
    return {
      ...appProps,
      pageProps: {
        user: null,
        preferencesList: null,
        mobile,
        pathname: appContext.ctx?.asPath,
      },
    };
  }
};

export default MyApp;
