import { FC } from 'react';
import DynamicRoutes from 'dynamic';

const {
  QualityIndicator,
  LegalMention,
  Confidentiality,
  Coaches,
  Cgu,
} = DynamicRoutes;

interface ModalPageProps {
    openModal: string
    handleClose: any
    handleClickLogo: any
}

const ModalPage: FC<ModalPageProps> = ({ openModal, ...props }) => (
  <>
    {openModal === 'cgu' && <Cgu {...props} />}
    {openModal === 'confidentialite' && <Confidentiality {...props} />}
    {openModal === 'mentions-legales' && <LegalMention {...props} />}
    {openModal === 'formateurs' && <Coaches {...props} />}
    {openModal === 'qualite' && <QualityIndicator {...props} />}
  </>
);

export default ModalPage;
