type AwsConfigType = {
  userPoolId?: string;
  userPoolWebClientId: string;
  identityPoolId: string;
  region: string;
};

type EndpointsType = {
  name: string;
  endpoint: string;
  region: string;
};

type APIConfigType = {
  endpoints: EndpointsType[];
};

type StorageType = {
  bucket: string;
  region: string;
  identityPoolId?: string;
  customPrefix: Record<string, string>;
};

type AuthType = {
  Auth?: AwsConfigType;
  API: APIConfigType;
  Storage: StorageType;
};

const awsConfig: AuthType = {
  Storage: {
    bucket: process.env.S3_BUCKET, // REQUIRED -  Amazon S3 bucket
    region: process.env.REGION, // OPTIONAL -  Amazon service region
    identityPoolId: process.env.LEARN_IDENTITY_POOL_ID || '',
    customPrefix: {
      protected: '',
      private: '',
      public: '',
    },
  },
  Auth: {
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: process.env.LEARN_USER_POOL_ID || '',
    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: process.env.LEARN_WEB_CLIENT_ID || '',
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId: process.env.LEARN_IDENTITY_POOL_ID || '',
    // REQUIRED - Amazon Cognito Region
    region: process.env.REGION || '',
  },
  API: {
    endpoints: [
      {
        name: 'hna',
        endpoint: process.env.API_URL || '',
        region: process.env.REGION || '|',
      },
    ],
  },
};

// console.log('awsConfig Storage ::', JSON.stringify(awsConfig));

export default awsConfig;
