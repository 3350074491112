import { useEffect } from 'react';
import useAuth from 'hooks/useAuth';
import SC from './style';

interface CookiesProps {
  handleCookie: (bool: boolean) => void
  setOpenModal: (value: string) => void
}

const Cookies: React.FC<CookiesProps> = ({ handleCookie, setOpenModal }) => {
  const { user } = useAuth();

  const handle = (accepted) => {
    if (accepted) {
      document.cookie = 'cookies=true;';
      return handleCookie(true);
    }
    return handleCookie(false);
  };

  useEffect(() => {
    if (user) handleCookie(true);
  }, [user]);

  return (
    <SC.Cookies>
      <p css={SC.text}>
        Nous utilisons des cookies pour optimiser notre site web et notre service.
        {' '}
        <button type="button" onClick={() => setOpenModal('confidentialite')}><a><b>Politique de cookies</b></a></button>
      </p>
      <div css={SC.buttons}>
        <button onClick={() => handle(false)} css={SC.buttonDenied} type="button">
          <p>Tout refuser</p>
        </button>
        <button onClick={() => handle(true)} css={SC.buttonAccept} type="button">
          <p>Accepter</p>
        </button>
      </div>
    </SC.Cookies>
  );
};

export default Cookies;
