import styled, { css } from 'styled-components';

const Cookies = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: ${({ theme: { mobile } }) => (mobile ? 'auto' : '19.58vw')};
  height: 13.98vh;
  position: absolute;
  background: red;
  bottom: 0;
  z-index: 100;
  right: 0;
  box-shadow: 0 3px 27px 0 rgb(0 0 0 / 33%);
  background-color: #fbfbfb;
  margin-bottom: 8.5185vh;
  margin-right: 1.719vw;
  padding: 1.04vh;
`;

const text = css`
  font-family: var(--font-family-sans);
  font-size: var(--size-16);
  color: var(--color-black);
  line-height: 1.25;
  text-align: left;

  button {
    background: none;
    padding: 0;
  }

  b {
  font-family: var(--font-family-sans);
  font-weight: 600;
  color: var(--color-black);
  }
`;

const buttons = css`
  display: flex;
  justify-content: space-around;
  width: 100%;

`;

const buttonDenied = css`
  height: 3.61vh;
  width: 7.7vw;
  border: none;
  background: white;
  color: var(--color-black);
  font-family: var(--font-family-sans);
  font-weight: 600;
  line-height: 1;
`;

const buttonAccept = css`
  height: 3.61vh;
  min-width: 7.7vw;
  border: none;
  background: var(--color-black);
  color: white;
  font-family: var(--font-family-sans);
  font-weight: 600;
  line-height: 1;
`;

export default {
  Cookies,
  text,
  buttons,
  buttonAccept,
  buttonDenied,
};
