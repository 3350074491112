import {
  createContext, ReactNode, useEffect, useState,
} from 'react';
import { withSSRContext } from 'aws-amplify';
import { useRouter } from 'next/router';
import { notification } from 'antd';
import hnaRequest from 'utils/hnaRequest';
import { isUserSubscribed } from 'utils/auth';
import useHistory from 'hooks/useHistory';

interface AuthProps {
  children: ReactNode
  user?: any
}

export type AuthContextType = {
  user: any,
  vendorId: any,
  coaches: any
  firstConnection: any
  isSubscribed: boolean
  login: (userAuthData: any) => void
  logout: (redirect?: boolean) => void
  setUser: (v: any) => void
  setProfileImage: (v: any) => void
  refetchProfile: () => void
  autoLogin: (t: string | string[]) => void
}

const initialContext = {
  user: null,
  coaches: null,
  vendorId: null,
  firstConnection: false,
  isSubscribed: false,
  autoLogin: () => {},
  login: () => {},
  logout: () => {},
  setUser: () => {},
  setProfileImage: () => {},
  refetchProfile: () => {},
};

export const AuthContext = createContext<AuthContextType>(initialContext);

const AuthProvider = ({
  children, user: ctxUser,
}: AuthProps) => {
  const [user, setUser] = useState(ctxUser);
  const [vendorId, setVendorId] = useState(initialContext.vendorId);
  const [coaches, setCoaches] = useState(null);
  const router = useRouter();
  const { savedUrl } = useHistory();

  const { Auth } = withSSRContext();

  const autoLogin = async (token) => {
    try {
      await Auth.signOut();
      localStorage.clear();
      setUser(null);

      if (router?.query?.vendorId) {
        const vId = router?.query?.vendorId as string;
        if (router?.query?.home?.[0] !== 'abonnement') localStorage.setItem('vendorId', vId);
        setVendorId(vId);
      }

      const response = await hnaRequest('post', 'connect/signin-user', { token });
      const { email } = response.data;
      const u: any = await Auth.signIn(email);
      await Auth.sendCustomChallengeAnswer(u, token);
      const userInfos = await hnaRequest('get', process.env.USER_URL, undefined);
      setUser(userInfos?.user);
      notification.success({
        message: 'Connexion réussie, bienvenue !',
      });

      Object.entries(router.query).forEach(([key]) => {
        if (key !== 'home' && key !== 'themes') router.query[key] = [];
      });

      if (router.query.method) {
        router.push('/mon-academy', undefined, { shallow: true });
      } else {
        router.push(router, undefined, { shallow: true });
      }
    } catch (error) {
      console.error({ error });
      // router.query.token = [];
      // router.push(router, undefined, { shallow: true });
    }
  };

  useEffect(() => {
    const vId = localStorage.getItem('vendorId');
    if (!vId && vendorId && router?.query?.home?.[0] !== 'abonnement') setVendorId(null);
  }, [router.asPath]);

  useEffect(() => {
    const getUser = async () => {
      try {
        await Auth.currentAuthenticatedUser();

        const userInfos = await hnaRequest('get', process.env.USER_URL, undefined);
        setUser(userInfos?.user);
      } catch (error) {
        setUser(null);
        console.error({ error });
      }
    };
    if (!ctxUser) getUser();
  }, [ctxUser]);

  const login = async (userAuthData) => {
    if (userAuthData) {
      setUser(userAuthData);
      router.push(savedUrl || '/mon-academy', undefined, { shallow: true });
    }
  };

  const logout = async (redirect = true) => {
    try {
      await Auth.signOut();
      localStorage.clear();
      if (redirect) router.push('/connect/login', undefined, { shallow: true });
      setUser(null);
    } catch (error) {
      console.log('error signing out: ', error);
    }
  };

  const setProfileImage = () => {
    let timesRun = 0;
    const interval = setInterval(async () => {
      timesRun += 1;
      if (timesRun === 2) {
        clearInterval(interval);
      }
      const userInfos = await hnaRequest('get', process.env.USER_URL, undefined);
      setUser(userInfos?.user);
    }, 2500);
  };

  const refetchProfile = async () => {
    const userProfile = await hnaRequest('get', 'user/refresh-profile', undefined);
    setUser(userProfile?.user);
  };

  useEffect(() => {
    const fetchCoachList = async () => {
      const ret = await hnaRequest('get', 'user/coach/list', undefined);
      setCoaches(ret);
    };

    if (router?.query?.vendorId && router?.query?.home?.[0] === 'abonnement') {
      setVendorId(router?.query?.vendorId);
    }

    fetchCoachList();
  }, []);

  return (
    <>
      <AuthContext.Provider value={{
        user,
        firstConnection: user && !user?.preferencesSet,
        logout,
        login,
        setUser,
        setProfileImage,
        refetchProfile,
        isSubscribed: isUserSubscribed(user),
        autoLogin,
        coaches,
        vendorId,
      }}
      >
        {children}
      </AuthContext.Provider>
    </>
  );
};

export default AuthProvider;
