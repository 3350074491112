import { Tooltip } from 'antd';
import Image from 'next/image';
import { PopupButton } from '@typeform/embed-react';
import { useRouter } from 'next/router';
import SC from './style';
import ImgTrainers from '../../../public/trainers.svg';
import ImgHandicap from '../../../public/handicap.svg';
import ImgQuality from '../../../public/quality-indicators.svg';
import ImgFormatrice from '../../../public/Formateur1.svg';
import useAuth from 'hooks/useAuth';

const Footer = ({ setOpenModal, isConnected }) => {
  const router = useRouter();
  const { user } = useAuth();
  return (
    <SC.DivFooter>
      <SC.DivBox>
        <div css={SC.imgWrap}>
          <Image
            alt="quality-indicators"
            src={ImgQuality}
            style={{
              maxWidth: '100%',
              height: 'auto',
            }}
          />
        </div>
        <button type="button" onClick={() => (isConnected ? router.push('/qualite', undefined, { shallow: true }) : setOpenModal('qualite'))}>Indicateurs qualité</button>
        <SC.DivSeparator />
        <div css={SC.imgWrap}>
          <Image
            alt="trainers"
            src={ImgTrainers}
            style={{
              maxWidth: '100%',
              height: 'auto',
            }}
          />
        </div>
        <button type="button" onClick={() => (isConnected ? router.push('/formateurs', undefined, { shallow: true }) : setOpenModal('formateurs'))}>Notre réseau</button>
        <SC.DivSeparator />
        <div css={SC.imgWrap}>
          <Image
            alt="handicap"
            src={ImgHandicap}
            style={{
              maxWidth: '100%',
              height: 'auto',
            }}
          />
        </div>
        <PopupButton
          id="IX7XRRII"
          tracking={{ email: user?.email, firstname: user?.firstName, name: user?.lastName }}
        >
          <Tooltip color="#5833ffd8" title="Happyneuron se tient à l'écoute de ses participants en situation de handicap et s'engage à tout mettre en oeuvre pour apporter une solution.">
            Information handicap
          </Tooltip>
        </PopupButton>
      </SC.DivBox>

      <SC.DivBox>
        <button type="button" onClick={() => (isConnected ? router.push('/mentions-legales', undefined, { shallow: true }) : setOpenModal('mentions-legales'))}>Mentions légales</button>
        <SC.DivSeparator />
        <button type="button" onClick={() => (isConnected ? router.push('/cgu', undefined, { shallow: true }) : setOpenModal('cgu'))}>Conditions générales d&#39;utilisation</button>
        <SC.DivSeparator />
        <PopupButton
          id="IX7XRRII"
          tracking={{ email: user?.email, firstname: user?.firstName, name: user?.lastName }}
        >
          Nous contacter
        </PopupButton>
        <SC.DivSeparator />
        <span>Tous droits réservés ©2022 Happyneuron Academy</span>
      </SC.DivBox>
    </SC.DivFooter>
  );
};

export default Footer;
