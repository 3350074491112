import { useEffect, useState } from 'react';

const useCookies = () => {
  const [displayCookie, setDisplayCookie] = useState(false);
  const [hasAccepted, setHasAccepted] = useState(false);

  useEffect(() => {
    const cookie = Object.fromEntries(document.cookie.split('; ').map((x) => x.split('=')));
    if (cookie.cookies === undefined) {
      setDisplayCookie(true);
    } else if (cookie.cookies) setHasAccepted(true);
  }, []);

  const handleCookie = (accepted) => {
    setHasAccepted(accepted);
    setDisplayCookie(false);
  };

  return { displayCookie, handleCookie, hasAccepted };
};

export default useCookies;
