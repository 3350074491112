import { createContext, useState } from 'react';
import useAuth from 'hooks/useAuth';
import analyticsRecord from 'utils/analyticsRecord';

type ITracking = {
    currSeconds: number
    inactiveSeconds: number
    totalSeconds: number
    scrollMaxPercent: number
    contentType: string
    time: string
    id: string
    wordsLen: number
}

type ITrackingContext = {
  tracking: ITracking
  setTracking: any
  handleUnMount: any
}

const initialContext = {
  tracking: {
    currSeconds: 0,
    inactiveSeconds: 0,
    totalSeconds: 0,
    scrollMaxPercent: 0,
    contentType: '',
    time: null,
    id: null,
    wordsLen: 0,
  },
  setTracking: () => {},
  handleUnMount: () => {},
};

export const TrackingContext = createContext<ITrackingContext>(initialContext);

const TrackingProvider = ({ children }) => {
  const [tracking, setTracking] = useState(initialContext.tracking);
  const { user } = useAuth();

  const handleUnMount = () => {
    setTracking((pS) => {
      const tracked = {
        timeOnPage: pS.totalSeconds,
        timeInactive: pS.inactiveSeconds,
        scrollMaxPercent: pS.scrollMaxPercent,
        userId: user?.id || null,
        contentType: pS.contentType,
        contentId: pS.id,
        time: pS.time,
        wordsLen: pS.wordsLen,
      };
      console.log({ tracked });
      analyticsRecord(tracked);
      return initialContext.tracking;
    });
  };

  return (
    <TrackingContext.Provider value={{ tracking, setTracking, handleUnMount }}>
      {children}
    </TrackingContext.Provider>
  );
};

export default TrackingProvider;
